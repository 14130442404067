<script setup>
import Button from '@/components/form/Button'
import { googleSdkLoaded } from "vue3-google-login"

const emits = defineEmits(['sendTokenToApi'])

const loginByGoogle = () => {
  googleSdkLoaded((google) => {
    google.accounts.oauth2.initTokenClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'email profile',
        callback: (response) => {
            emits('sendTokenToApi', {type: 'google', token: response.access_token})
        }
    }).requestAccessToken()
  })
}
</script>
<template>
    <Button :data="{
      title: 'Google',
      type: 'primary',
      icon: 'fab fa-google',
      loading: false,
    }" class="btn_social" @click="loginByGoogle"/>

</template>