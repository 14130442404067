<template>
  <div>
  <Button :data="btnGoogleAuth" class="btn_social" @click="openSocialAuth('google')"/>
  <Button :data="btnFacebookAuth" class="btn_social" @click="openSocialAuth('facebook')"/>
  </div>
</template>

<script>
import Button from '@/components/form/Button'
import {reactive} from "@vue/reactivity";

export default {
  name: "SocialAuthButtons",
  components: {
    Button
  },
  setup(props, {emit}) {

    let btnGoogleAuth = reactive({
      title: 'Google',
      type: 'primary',
      icon: 'fab fa-google',
      loading: false,
    })

    let btnFacebookAuth = reactive({
      title: 'Facebook',
      type: 'primary',
      icon: 'fab fa-facebook-f',
      loading: false,
    })

    async function openSocialAuth(type) {

      localStorage.removeItem('social_auth_token')
      localStorage.removeItem('tempId')

      btnGoogleAuth.loading = type === "google"
      btnFacebookAuth.loading = type === "facebook"

      const tempId = URL.createObjectURL(new Blob([])).substr(-36)

      localStorage.setItem('tempId', tempId)
      let location = window.location

      let mainQuotingPortalUrl = process.env.VUE_APP_QUOTING_PORTAL_DOMAIN
      if (!mainQuotingPortalUrl) mainQuotingPortalUrl = "https://ss-p3-quoting.conceptangle.com.au" // For testing

      const callbackUrl = encodeURIComponent(`${location.protocol}//${location.host}/auth/social-callback/${type}/${tempId}`) //Token to be sent as params
      const newUrlToBeOpened = `${mainQuotingPortalUrl}/auth/${type}?callback=${callbackUrl}`

      window.open(newUrlToBeOpened)

      let searchSocialToken = setInterval(() => {
        if (localStorage.getItem('social_auth_token')) {
          clearInterval(searchSocialToken)
          emit('sendTokenToApi', {type, token: localStorage.getItem('social_auth_token')})
          localStorage.removeItem('social_auth_token')
          localStorage.removeItem('tempId')

          setTimeout(() => {
            btnGoogleAuth.loading = false
            btnFacebookAuth.loading = false
          },2000)
        }
      }, 300)
    }


    return {
      btnGoogleAuth,
      btnFacebookAuth,
      openSocialAuth,

    }
  }
}
</script>

