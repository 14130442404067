<template>
  <div>
    <div class="register-form">
      <span class="register-form__desc md:tw-w-4/5">In order to save this item and add more items to your estimate, you need to sign up.</span>
      <div class="register-form__content" style="justify-content: center;">
        <!-- <div class="register-form__block block__auth-btns"> -->
          <!-- <div class="block__title">Sign Up Using</div> -->
          <!-- <GoogleAuthButtonVue @sendTokenToApi="(data) => setAuth(data.type,data.token)"/> -->
          <!-- <SocialAuthButtons @sendTokenToApi="(data) => setAuth(data.type,data.token)"/> -->
          <!-- <span class="link_dotted" @click="login">Login</span> -->
        <!-- </div> -->
        <div class="register-form__block">
          <div class="block__title">Create your account</div>
          <div class="block__row block__item">
            <InputGroup v-model="formAuth.first_name.value" :data="formAuth.first_name" class="row__item"/>
            <InputGroup v-model="formAuth.last_name.value" :data="formAuth.last_name" class="row__item"/>
          </div>

          <InputGroup v-model="formAuth.email.value" :data="formAuth.email" class="block__item"/>
          <InputGroup v-model="formAuth.password.value" :data="formAuth.password" class="block__item"/>
          <InputGroup v-model="formAuth.password_confirmation.value" :data="formAuth.password_confirmation"
                      class="block__item"/>

          <Button :data="btnSubmit" class="block__btn-submit- tw-w-full" @click="submit"/>

          <div class="block__title ">Already have an account?</div>

          <Button :data="btnLogin" class="block__btn-submit- tw-w-full" @click="login"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/form/Button'
import InputGroup from '@/components/form/InputGroup'
import {reactive} from '@vue/reactivity'
import {clearValidationError, getValidationError, getValueFromObject} from '@/helpers/objects'
import {useRouter} from 'vue-router'
import api from '@/services/auth/auth.service.js'
import Modal from '@/components/elements/Modal'
import {notify} from "@kyvg/vue3-notification"
import SocialAuthButtons from '@/components/auth/SocialAuthButtons'
import GoogleAuthButtonVue from '@/components/auth/GoogleAuthButton'

export default ({
  components: {
    Button,
    InputGroup,
    Modal,
    SocialAuthButtons,
    GoogleAuthButtonVue,
  },
  props: ['isModal'],
  setup(props, {emit}) {
    const router = useRouter()

    const modalData = reactive({
      show: false,
      text: '',
    })


    const btnSubmit = reactive({
      title: 'SIGN up',
      type: 'primary',
      href: '',
      icon: 'fas fa-user-plus',
      loading: false,
    })

    const btnLogin = reactive({
      title: 'LOG IN',
      type: 'primary',
      href: '',
      icon: 'fas fa-sign-in-alt',
      loading: false,
    })

    const formAuth = reactive({
      first_name: {label: 'First Name*', placeholder: 'First Name', value: '', type: 'text', error: ''},
      last_name: {label: 'Last Name*', placeholder: 'Last Name', value: '', type: 'text', error: ''},
      email: {label: 'Email*', placeholder: 'Email', value: '', type: 'text', error: ''},
      password: {label: 'Password*', placeholder: 'Password', value: '', type: 'password', error: ''},
      password_confirmation: {placeholder: 'Confirm Password', value: '', type: 'password', error: ''},
    })

    async function submit() {
      clearValidationError(formAuth)

      const data = getValueFromObject(formAuth)
      const result = await api.register(data)

      btnSubmit.loading = false

      if (result.errors) {
        getValidationError(result.errors, formAuth)
        return
      }
      if (result.message) showModal(result.message)
      else setToken(result)
    }

    async function setAuth(type, token) {
      const result = await api.authSocial(type, token)
      setToken(result)
    }

    function setToken(data) {
      if (data.token) {
        localStorage.setItem('token', data.token)
        localStorage.setItem('user', JSON.stringify(data.user))
        if (!props.isModal) router.push({name: 'my-estimates'})
        else emit('registered')
        localStorage.removeItem('social_auth_token')
        localStorage.removeItem('tempId')
      } else {
        showModal(data.message)
      }
      if (data.success_message) showSuccess(data.success_message)
    }

    function login() {
      if (!props.isModal) router.push({name: 'login'})
      else emit('showLoginModal')
    }

    function showModal(text) {
      emit('showModal', text)
    }

    function showSuccess(message) {
      notify({
        type: 'success',
        title: message,
        duration: 5000
      })
    }

    return {
      btnLogin,
      btnSubmit,
      formAuth,
      modalData,
      submit,
      login,
      showSuccess,
      setAuth
    }

  },
})
</script>

<style>
.v-facebook-login {
  cursor: pointer !important;
  width: 100% !important;
  height: 100% !important;
}
</style>

<style lang="scss" scoped>

::v-deep .icons__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-form {
  // width: 624px;
  min-height: 358px;
  padding: 17px 34px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;



  &__desc {
    font-size: 16px;
    line-height: 18px;
    // width: 322px;
    display: inline-block;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 21px;
  }

  &__block {
    // width: calc(100% - 230px);
    width: 100%;
  }
}

.block {
  &__title {
    font-size: 18px;
    line-height: 20px;
    height: 23px;
    text-align: center;
    text-transform: uppercase;
    font-family: Sansation;
  }

  ::v-deep &__auth-btns {
    width: 191px;

    .btn {
      width: 100%;
      margin-bottom: 41px;

      &:first-of-type {
        margin-top: 22px;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    .row__item {
      width: calc(50% - 6px);
    }
  }

  &__item {
    margin-bottom: 4px;
    width: 100%;

    &:last-of-type {
      margin-top: 11px;
    }
  }

  &__btn-submit {
    margin: 11px auto 0;
    width: 215px;
  }
}

::v-deep .btn_social {
  svg {
    position: absolute;
    left: 18px;
    top: 7px;
    height: 15px;
  }

}

@media (max-width: 768px) {
  .register-form {
    width: 100%;
    padding: 80px 20px 90px;

    &__desc {
      width: auto;
    }

    &__content {
      flex-direction: column;
    }

    &__block {
      width: 100%;
    }
  }
  .block {
    &__title {
      width: 100%;
    }

    ::v-deep &__auth-btns {
      width: 100%;
      margin-bottom: 30px;

      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:first-of-type {
          margin-top: 20px;
        }
      }
    }

    &__btn-submit {
      margin: 20px auto 0;
    }
  }
}
</style>
